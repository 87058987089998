<template>
  <div class="form">
    <div class="form-title">
      <span>{{$t("form_Q12")}}</span>
      <span class="danger-text">{{$t("form_require")}}</span>
    </div>

    <section class="form-container">

      <!--form1-->
      <section>
        <div class="form-sub-title">
          <span>{{$t("form_Q12_form1")}}</span>
          <span class="danger-text">{{$t("form_require")}}</span>
        </div>

        <div class="form-post-code">
          <div class="mr-2">{{$t("form_postal")}}</div>

          <v-text-field
            outlined
            dense
            hide-details
            v-model="postalCode.code1"
            :rules="rules.code1"
            maxlength="3"
            style="width: 25%"
          ></v-text-field>

          <div class="mx-2">ー</div>

          <v-text-field
            outlined
            dense
            hide-details
            v-model="postalCode.code2"
            :rules="rules.code2"
            maxlength="4"
            style="width: 30%"
          ></v-text-field>

          <v-btn outlined color="#132d7a" class="ml-3" style="width: 10%" @click="searchAddress()">
            <span>{{$t("form_search_label")}}</span>
          </v-btn>

          <div class="danger-text pl-1" style="width: 100%">
            <span v-if="postalCode.errorFrag">{{$t('validate_postCode')}}</span>
          </div>
        </div>

        <div class="form-address">
          <div class="mr-2">{{$t("form_address")}}</div>
          <v-text-field
            outlined
            dense
            hide-details
            :label="$t('form_placeholder')"
            v-model="data.address"
            :rules="rules.address"
            @change="setData_address()"
          ></v-text-field>
        </div>
      </section>

      <!--form2-->
      <section>
        <div class="form-sub-title" style="margin-top: 1vw">
          <span>{{$t("form_Q12_form2")}}</span>
          <span class="danger-text">{{$t("form_require")}}</span>
        </div>

        <div>
          <div class="form_desc mb-1">
            {{$t("form_Q12_form2_desc")}}
          </div>

          <v-text-field
            outlined
            dense
            :label="$t('form_placeholder')"
            v-model="data.q14"
            :rules="rules.q14"
            counter="255"
            @change="setData_q14()"
          ></v-text-field>
        </div>
      </section>

      <!--form3-->
      <section>
        <div class="form-sub-title" style="margin-top: 0.5vw">
          <span>{{$t("form_Q12_form3")}}</span>
          <span class="danger-text">{{$t("form_require")}}</span>
        </div>

        <div>
          <div class="form_desc mb-1">
            {{$t("form_Q12_form3_desc")}}
          </div>

          <div class="form-checkbox">
            <v-checkbox
              v-model="data.q15"
              label="はい"
              color="#132d7a"
              value="はい"
              @change="setData_q15()"
            ></v-checkbox>

            <v-checkbox
              class="ml-3"
              v-model="data.q15"
              label="いいえ"
              color="#132d7a"
              value="いいえ"
              @change="setData_q15()"
            ></v-checkbox>

            <v-checkbox
              class="ml-3"
              v-model="data.q15"
              label="わからない"
              color="#132d7a"
              value="わからない"
              @change="setData_q15()"
            ></v-checkbox>
          </div>

          <v-text-field
            v-if="data.q15 === 'はい'"
            outlined
            dense
            :label="'はいの場合は'+$t('form_placeholder')"
            v-model="data.q15_1"
            :rules="rules.q15"
            counter="255"
            @change="setData_q15_1()"
          ></v-text-field>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: 'Form12',
  data() {
    return {
      data: {
        address: "",
        q14: "",
        q15: "",
        q15_1: "",
      },
      postalCode: {
        code1: "",
        code2: "",
        errorFrag: false
      },
      value: [],
      rules: {
        code1: [
          v => !!v || '入力してください',
          v => (v && v.length <= 3) || '3文字以内で入力してください',
        ],
        code2: [
          v => !!v || '入力してください',
          v => (v && v.length <= 4) || '4文字以内で入力してください'
        ],
        address: [
          v => !!v || '入力してください',
          v => (v && v.length <= 255) || '255文字以内で入力してください'
        ],
        q14: [
          v => !!v || '入力してください',
          v => (v && v.length <= 255) || '255文字以内で入力してください'
        ],
        q15: [
          v => !!v || '入力してください',
          v => (v && v.length <= 255) || '255文字以内で入力してください'
        ]
      }
    }
  },
  mounted() {
    let sessionItem_zipcode = sessionStorage.getItem('zipcode')
    let sessionItem_adress = sessionStorage.getItem('address')
    let sessionItem_q14 = sessionStorage.getItem('q14')
    let sessionItem_q15 = sessionStorage.getItem('q15')
    let sessionItem_q15_1 = sessionStorage.getItem('q15_1')

    if(sessionItem_zipcode) {
      this.postalCode.code1 = sessionItem_zipcode.substring(0, 3)
      this.postalCode.code2 = sessionItem_zipcode.substring(3)
    }

    if(sessionItem_adress) {
      this.data.address = sessionItem_adress
    }

    if(sessionItem_q14) {
      this.data.q14 = sessionItem_q14
    }

    if(sessionItem_q15) {
      this.data.q15 = sessionItem_q15
    }

    if(sessionItem_q15_1) {
      this.data.q15_1 = sessionItem_q15_1
    }
  },
  methods: {
    setData_address() {
      if(this.data.address) {
        sessionStorage.setItem('address', this.data.address)
      } else {
        sessionStorage.setItem('address', '')
      }
    },
    setData_q14() {
      if(this.data.q14) {
        sessionStorage.setItem('q14', this.data.q14)
      } else {
        sessionStorage.setItem('q14', '')
      }
    },
    setData_q15() {
      if(this.data.q15) {
        sessionStorage.setItem('q15', this.data.q15)

        if(this.data.q15 === 'いいえ' || this.data.q15 === 'わからない') {
          this.data.q15_1 = ''
          sessionStorage.setItem('q15_1', "")
        }
      } else {
        this.data.q15_1 = ''
        sessionStorage.setItem('q15', '')
        sessionStorage.setItem('q15_1', "")
      }
    },
    setData_q15_1() {
      if(this.data.q15_1) {
        sessionStorage.setItem('q15_1', this.data.q15_1)
      } else {
        sessionStorage.setItem('q15_1', '')
      }
    },
    searchAddress() {
      const url = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
      const code = this.postalCode.code1 + "-" + this.postalCode.code2

      if(code.match(/^\d{3}-?\d{4}$/)) {
        this.postalCode.errorFrag = false

        axios.get(url + code).then((res) => {
          const address = res.data.results[0]
          this.data.address = address.address1 + address.address2 + address.address3
          let zipcode = this.postalCode.code1 + this.postalCode.code2

          sessionStorage.setItem('zipcode', zipcode)
          sessionStorage.setItem('address', this.data.address)
        })
        .catch(error => {
          console.error(error)
        })
      } else {
        this.postalCode.errorFrag = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;

  .form-title {
    font-size: max(16px, 1.8vw);
    font-weight: bold;
    color: #132d7a;
    text-align: center;
  }

  .form-container {
    margin: 0 15%;
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: center;

    .form-sub-title {
      font-size: max(14px, 1.2vw);
      font-weight: bold;
      margin-bottom: 10px;
    }

    .form_desc {
      font-size: max(12px, 1.2vw);
      color: gray;
    }

    .form-post-code {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .form-address {
      margin-top: 10px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .form-checkbox {
      display: flex;
    }
  }

}

</style>